/* eslint-disable @next/next/no-img-element */
"use client";
import React from "react";
import "./ClientSlider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
import {
    StraightQuotes,
  ClientReviews1,
  ClientReviews2,

} from "@/public/Img/Home";
import ReactStars from "react-stars";

export const dynamic = "force-dynamic";

const ClientSlider = ({ content, path, portfolioTitle }) => {
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const portTitle = portfolioTitle ? portfolioTitle : "Client Testimonial";

    const ClientSliderData = {
        title: portTitle,
        subtitle: "",
        services: [
            {
                id: 1,
                clientImage: ClientReviews1,
                alt: "client reviews",
                clientDescription:
                    "Tanθ delivered outstanding NFT development services that fulfilled our expectations. All components of our project was managed with care and precision thanks to their industrious focus to detail and proactive communication.",
                clientName: "Jesko Hoffmann",
                clientPosition: "Founder, African NFT",
                rating: 5.0,
            },
            {
                id: 2,
                clientImage: ClientReviews2,
                alt: "client reviews",
                clientDescription:
                    "Tanthetaa's expertise in metaverse development is unmatched. Working with them was a game-changer for my virtual project. Their ability to understand and execute my vision surpassed all expectations. Each element of the virtual world they crafted was infused with creativity and precision. What impressed me the most was their commitment to excellence, ensuring every detail was perfected. Collaborating with Tanthetaa made the entire process smooth and enjoyable. If you're considering exploring the metaverse, look no further than Tanthetaa for unparalleled expertise and innovation.",
                clientName: "Uday Kumar S",
                clientPosition: "Manager, Blockchain Developemnt Company",
                rating: 4.8,
            },
            {
                id: 3,
                clientImage: ClientReviews1,
                alt: "client reviews",
                clientDescription:
                    "It is my pleasure of working with Tan Software Studio and I must say, I am so happy with their services. From start to finish, they were professional, knowledgeable, and always went above and beyond to ensure our project was a success.First of all, their technical expertise was exceptional. They always try to understand of our project requirements and were able to recommend the best solutions to meet our needs. Their coding skills were exceptional, and they were able to deliver high-quality, bug-free code on time and within budget.Moreover, their communication skills were outstanding. They were always available to answer our questions and address any concerns we had no matter its working hour or not. They were also able to explain complex technical concepts in a way that was easy for our team to understand, which was a huge help.Finally, their commitment to customer satisfaction was truly impressive. They went out of their way to ensure that we were happy with the final product and were willing to make changes and adjustments until we were completely satisfied.",
                clientName: "Mohammed Nurul Haque",
                clientPosition: "Technical Director of Tech Innovators Inc",
                rating: 4.5,
            },
            {
                id: 4,
                clientImage: ClientReviews2,
                alt: "client reviews",
                clientDescription:
                    "Tanθ developed a secure and user-friendly web wallet for me. Their expertise and efficient communication ensured a seamless process, resulting in a reliable solution for managing digital assets.",
                clientName: "Khushi Tiwari",
                clientPosition: "CTO - Tech Innovations Inc.",
                rating: 5.0,
            },
            {
                id: 5,
                clientImage: ClientReviews1,
                alt: "client reviews",
                clientDescription:
                    "Tanθ exceeded expectations in developing my DeFi crowdfunding platform. Their expertise in decentralized finance and commitment to my vision were remarkable. Clear communication and timely updates made the process smooth. They ensured security and user-friendly features, setting my platform apart. Tanθ's dedication to excellence is evident, and I highly recommend them to anyone venturing into DeFi solutions. They turned my crowdfunding idea into a reality with professionalism and skill.",
                clientName: "Elvina M.",
                clientPosition: "Head of Development at NFT Tech Solutions",
                rating: 4.5,
            },
            {
                id: 6,
                clientImage: ClientReviews2,
                alt: "client reviews",
                clientDescription:
                    "Had an awesome experience with Tanθ Software Studio. Always available when you need them. They were patient with us and also didn't hesitate when we asked them for some late changes.",
                clientName: "Ayan Khan",
                clientPosition:
                    "Senior Technology Consultant at Oyelabs Technologies",
                rating: 4.5,
            },
            {
                id: 7,
                clientImage: ClientReviews1,
                alt: "client reviews",
                clientDescription:
                    "I recently worked with Tanθ to develop a new web app for my business, and I couldn't be happier with the results. The team was professional, knowledgeable, and easy to work with throughout the entire process.From the initial consultation to the final launch, tan θ kept me informed every step of the way and made sure that my needs were met. They took the time to listen to my ideas and provided valuable feedback to ensure that the app was exactly what I needed.The quality of their work was exceptional, and they were able to deliver the project on time and within budget. I was impressed with their attention to detail and commitment to excellence, and I would highly recommend tan θ to anyone looking for top-notch software development services.Overall, I had a great experience working with tan θ, and I look forward to collaborating with them on future projects. Thanks for all of your hard work!",
                clientName: "Amar krishna",
                clientPosition:
                    "Chief Web Application Officer, FutureTech Dynamics",
                rating: 5.0,
            },
        ],
    };

    return (
        <>
            <section className="container mx-auto my-20 lg:my-[140px] px-4 md:px-24 lg:px-24 xl:px-0">
                <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold pb-7 text-center font-manrope">
                    {content?.title
                        ? `${content?.title}`
                        : `${ClientSliderData?.title}`}
                </h2>

                {content?.subtitle && (
                    <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold pb-7 text-center font-manrope">
                        {content?.subtitle}
                    </h2>
                )}

                <h4 className="text-center text-[#59587C] font-normal lg:pb-8 2xl:px-72 2xl:mx-20 xl:px-60 xl:mx-10">
                    {!content
                        ? ClientSliderData?.discription
                        : content?.discription}
                </h4>

                {!content ? (
                    <Slider {...settings} className="z-0">
                        {ClientSliderData?.services?.map((element) => {
                            return (
                                <div
                                    className="grid grid-cols-1 xl:grid-cols-3 mt-14 mb-10 client-details"
                                    key={element.id}
                                >
                                    <div className="m-auto mb-10 lg:mb-0">
                                        <Image
                                            src={element?.clientImage}
                                            alt={element?.alt}
                                            className="w-40 md:w-52 lg:w-80"
                                        />
                                    </div>
                                    <div className="col-span-2 px-4 md:px-7 xl:px-12 my-auto">
                                        <div className="pb-5">
                                            <div className="flex justify-start gap-5">
                                                <Image
                                                    src={StraightQuotes}
                                                    alt="Straight Quotes"
                                                    className="w-5 lg:w-10"
                                                />
                                                {/* <div className="flex items-center justify-center gap-5 font-bold text-2xl"> */}
                                                {/* {element?.rating} */}
                                                <ReactStars
                                                    count={5}
                                                    value={element?.rating}
                                                    // onChange={ratingChanged}
                                                    edit={false}
                                                    size={32}
                                                    color2={"#410EAD"}
                                                />
                                                {/* </div> */}
                                            </div>
                                            <p className="text-sm lg:text-lg text-center text-[#59587C] font-normal py-5 client-slider-subtitle">
                                                {element.clientDescription}
                                            </p>
                                            <div className="flex justify-end">
                                                <Image
                                                    src={StraightQuotes}
                                                    alt="Straight Quotes"
                                                    className="w-5 lg:w-10"
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <h3 className="text-lg lg:text-xl font-bold tracking-wide font-manrope">
                                                {element.clientName}
                                            </h3>
                                            <h4 className="text-xs lg:text-sm tracking-wide text-[#410EAD] font-manrope">
                                                {element.clientPosition}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                ) : (
                    <Slider {...settings} className="z-0">
                        {content?.services?.map((element) => {
                            return (
                                <div
                                    className="grid grid-cols-1 lg:grid-cols-3 mt-14 mb-10 client-details"
                                    key={element?.id}
                                >
                                    <div className="m-auto mb-10 lg:mb-0">
                                        <img
                                            src={`${`/assets/${path}/${element?.clientImage}`}.svg`}
                                            alt={element?.alt}
                                            className="w-40 md:w-52 lg:w-80 m-auto"
                                        />
                                    </div>
                                    <div className="col-span-2 px-4 md:px-14 xl:px-28 my-auto">
                                        <div className="pb-5">
                                            <div className="flex justify-start">
                                                <Image
                                                    src={StraightQuotes}
                                                    alt="Straight Quotes"
                                                    className="w-5 lg:w-10"
                                                />
                                            </div>
                                            <p className="text-sm lg:text-lg text-center text-[#59587C] font-normal py-5 client-slider-subtitle">
                                                {element?.clientDescription}
                                            </p>
                                            <div className="flex justify-end">
                                                <Image
                                                    src={StraightQuotes}
                                                    alt="Straight Quotes"
                                                    className="w-5 lg:w-10"
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <h3 className="text-lg lg:text-xl font-bold tracking-wide font-manrope">
                                                {element?.clientName}
                                            </h3>
                                            <h4 className="text-xs lg:text-sm tracking-wide text-[#410EAD] font-manrope">
                                                {element?.clientPosition}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                )}
            </section>

            {/* </section> */}
        </>
    );
};

export default ClientSlider;
